import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/img/logo2.svg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Ripples from "react-ripples";
import {
  getContactsAsync,
  getContactsResponse,
} from "../../service/slices/contactSlice";
import { useDispatch, useSelector } from "react-redux";

function Header(props) {
  // Add and Remove Class on scroll
  const [scrolltopdata, setscrolltopdata] = useState("");

  const _getContactsResponse = useSelector(getContactsResponse);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_getContactsResponse?.response?.status == 200) {
      localStorage.setItem(
        "contact",
        JSON.stringify(_getContactsResponse?.response?.data)
      );
    }
  }, [_getContactsResponse]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 20) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("scrolled");
      }
    });
    dispatch(getContactsAsync());
  }, []);

  return (
    <div>
      <Navbar expand="lg" className={`mainheader ${scrolltopdata}`}>
        <Container>
          <div className="header_inner_wrapper w-100">
            <div className="d-flex align-items-center justify-content-between w-100">
              <Link to="/">
                <img alt="logo" src={logo} />
              </Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/* <Link className="nav-link" to="/">Home</Link> */}
                <Link className="nav-link" to="#">
                  Company
                </Link>
                <Link className="nav-link" to="#">
                  Features
                </Link>

                {/* <Dropdown className="custom_dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ padding: "0 48px 0 24px" }}
                  >
                    Company
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Real time protections
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Counterfeit Reporting
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Business Tracking
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      employee management
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Seamless integration
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="custom_dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    style={{ padding: "0 48px 0 24px" }}
                  >
                    Features
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Real time protections
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Counterfeit Reporting
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Business Tracking
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      employee management
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Seamless integration
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Link className="nav-link" to="/pricing">
                  Pricing
                </Link>
              </Nav>
              <div className="buttonBox">
                <div style={{ marginRight: "24px" }}>
                  <Ripples color="#004ac340" during={1200}>
                    <Link to={"/contact-sale"}>
                      <Button variant="" className="contact_sale">
                        Contact Sales
                      </Button>
                    </Link>
                  </Ripples>
                </div>
                <Ripples color="#ffffff47" during={1200}>
                  <Button variant="">Request Demo</Button>
                </Ripples>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
