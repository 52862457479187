import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../../assets/img/logo_white2.svg";
import linkedin from "../../assets/img/linkedin.svg";
import Facebook from "../../assets/img/Facebook.svg";
import Instagram from "../../assets/img/Instagram.svg";
import Twitter from "../../assets/img/Twitter.svg";
import { Link } from "react-router-dom";
import Ripples from "react-ripples";

function Footer(props) {
  const date = new Date().getFullYear();
  const [contacts, setContact] = useState();
  useEffect(() => {
    const contact = localStorage.getItem("contact");
    if (contact && contact != "") {
      setContact(JSON.parse(contact)[0]);
    }
  }, []);
  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <h3>Company</h3>
            <ul>
              <li>
                <Link to={"/about"}>About Us</Link>
              </li>
              <li>
                <Link to={"/sitemap"}>Sitemap</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <h3>Legal</h3>
            <ul>
              <li>
                <Link to={"/term-condition"}>Term of Services</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <h3>Use Cases</h3>
            <ul>
              <li>
                <Link to={"/account-management"}>Account Management</Link>
              </li>
            </ul>
          </div> */}
          <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <h3>Resources</h3>
            <ul>
              {/* <li>
                <a href="#">Blog</a>
              </li> */}
              <li>
                <Link to={"/help-support"}>Help & Support</Link>
              </li>
              {/* <li>
                <a href="#">Cookie Settings</a>
              </li> */}
              <li>
                <Link to={"/careers"}>Careers</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4 mb-md-5 mb-lg-0 offset-0 offset-lg-2">
            <h4>Subscribe for newsletters</h4>
            <div className="newsletterBox">
              <Form.Control placeholder="Your email address" />
              <Ripples color="#ffffff47" during={1200}>
                <Button variant="">Subscribe</Button>
              </Ripples>
            </div>
          </div>

          <div className="col-md-12">
            <span className="borderLine"></span>
          </div>

          <div className="col-md-12">
            <div className="footer_mini">
              <div>
                <Link to={"/"}>
                  <img alt="icon" src={logo} />
                </Link>
              </div>
              <p>©{date} Clarity. All rights reserved.</p>

              <ul className="sociallinks">
                <li>
                  <a href={contacts ? contacts.linkedin : "#"} target="_blank">
                    <img alt="icon" src={linkedin} />
                  </a>
                </li>
                <li>
                  <a href={contacts ? contacts.facebook : "#"} target="_blank">
                    <img alt="icon" src={Facebook} />
                  </a>
                </li>
                <li>
                  <a href={contacts ? contacts.instagram : "#"} target="_blank">
                    <img alt="icon" src={Instagram} />
                  </a>
                </li>
                <li>
                  <a href={contacts ? contacts.twitter : "#"} target="_blank">
                    <img alt="icon" src={Twitter} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
