import React from "react";
import { Button } from "react-bootstrap";
import rating from "../../assets/img/rate-stars.svg";
import phone from "../../assets/img/phone1.webp";
import tab from "../../assets/img/tab1.webp";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function HeroSection(props) {
  return (
    <section className={props.class}>
      <div className="container">
        <div className="row">
          {/* animate__animated animate__slideInLeft */}
          <div className="col-md-6">
            <div className="animateHeroContent">
              <h1>
                Combat Counterfeiting Protect Your Cosmetics'{" "}
                <span>Integrity</span>
              </h1>
              <p>
                Take action against counterfeiters in the hair and beauty
                industry with Clarity Codes powerful anti-counterfeit solution.
              </p>
              <Ripples color="#ffffff47" during={1200}>
                <Link
                  to={process.env.REACT_APP_REGISTRATION_URL}
                  target="_blank"
                >
                  <Button variant="" className="subscribeBtn">
                    Act Now!
                  </Button>
                </Link>
              </Ripples>

              <div className="rating">
                <p>Outstanding Reviews from Customers Worldwide</p>
                <div>
                  <img alt="icon" src={rating} />
                  <h2>
                    4.9 / <span>5.0</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="position-relative h-100 d-flex justify-content-end">
              <div className="bg-layer">
                {/* <LazyLoadImage
                  src={phone}
                  className="phoneFrame animate__animated animate__slideInUp"
                />
                <LazyLoadImage
                  src={tab}
                  className="tabFrame animate__animated animate__slideInRight"
                /> */}
                <img alt="icon" className="phoneFrame" src={phone} />

                <img alt="icon" className="tabFrame" src={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
