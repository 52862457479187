import React, { useEffect, useState } from "react";
import HeroSection from "../hero/HeroSection";
import Partner from "../partners/Partner";
import BestServices from "../bestservices/BestServices";
import ClaritySolutions from "../claritysolutions/ClaritySolutions";
import ActiveUser from "../activeuser/ActiveUser";
import StartHassle from "../starthassle/StartHassle";
import Client from "../clients/Client";
import DownloadApp from "../downloadapp/DownloadApp";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnersAsync,
  getPartnersResponse,
  getUserLocationsResponse,
  getUserLocationsAsync
} from "../../service/slices/homeSlice";
import { ColorRing } from "react-loader-spinner";

function Home(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [activeLocations,setActiveLocations] = useState([]);
  const dispatch = useDispatch();
  const _getPartnersResponse = useSelector(getPartnersResponse);
  const _getUserLocationsResponse = useSelector(getUserLocationsResponse);
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getPartnersAsync({
        limit: 50,
      })
    );
    dispatch(
      getUserLocationsAsync()
    );
  }, []);

  useEffect(() => {
    if (_getPartnersResponse?.response?.status == 200) {
      setIsLoading(_getPartnersResponse?.isLoading);
      setPartnersData(_getPartnersResponse?.response?.data);
      
    }
  }, [_getPartnersResponse]);

  useEffect(() => {
    if (_getUserLocationsResponse?.response?.status == 200) {
      
      setIsLoading(_getUserLocationsResponse?.isLoading);
     setActiveLocations(_getUserLocationsResponse?.response?.data);
    }
  }, [_getUserLocationsResponse]);

  return (
    <>
    {!isLoading ? (
      <>
        <HeroSection class="herosection" />
        <Partner
          class="partnerSection"
          partnersData={partnersData}
          isLoading={isLoading}
        />
        <BestServices class="bestServices" />
        <ClaritySolutions class="claritySolution" />
        <ActiveUser activeLocations={activeLocations} class="activeUser" />
        <StartHassle class="startHassle" />
        <Client class="clientSection" />
        <DownloadApp class="downloadapp" />
      </>
    ) : (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              />
            </div>
          )}
    </>
  );
}

export default Home;
