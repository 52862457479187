import React from "react";
import tickcircle from "../../assets/img/tickcircle.svg";
import tickCircle_active from "../../assets/img/tickCircle_active.svg";
import doller_sign from "../../assets/img/doller_sign.svg";
import { Button } from "react-bootstrap";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
function PlanCard(props) {
  const { planeData } = props;
  return (
    <div className="col-md-12 ">
      <div
        className={`${props.class} ${
          planeData?.tags ? "custom_card active" : ""
        }`}
      >
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "16px" }}
        >
          <h3>{planeData?.name}</h3>

          {planeData?.planType !== "Contact" ? (
            <h6 style={{display : planeData?.tags ? 'block' :'none'}}>{planeData?.tags}</h6>
          ) : (
            ""
          )}

        </div>
        <p>
          {planeData?.planType == "Contact"
            ? "Choose the plan that suits your needs best"
            : planeData?.description}
        </p>
        <span className="seprater"></span>

        <ul>
          {planeData?.users > -1 && planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                {`${planeData?.users} Staff`}
              </li>
            </>
          ) : planeData?.planType == "Contact" ? (
            <p className="des_paragraph">{planeData?.description}</p>
          ) : (
            ""
          )}

          {/* {planeData?.BrandAndCertificate &&
          planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                Add Brand and cerificates
              </li>
            </>
          ) : (
            ""
          )} */}


          {planeData?.permission_type  && planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                {`${planeData?.permission_type} staff permission`}
              </li>
            </>
          ) : (
            ""
          )}

          {planeData?.export_type  && planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                {`${planeData?.export_type?.toString().replace(',',' & ')} format batch export`}
              </li>
            </>
          ) : (
            ""
          )}


          {planeData?.printCodes > -1 && planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                {`Print ${planeData?.printCodes} codes`}
              </li>
            </>
          ) : (
            ""
          )}

          {planeData?.domainName && planeData?.planType !== "Contact" ? (
            <>
              <li>
                <img alt="icon" className="unactive" src={tickcircle} />
                <img alt="icon" className="active" src={tickCircle_active} />
                Set Your Domain
              </li>
            </>
          ) : (
            ""
          )}
        </ul>

        <div className="priceBox" style={{visibility:planeData?.planType == "Contact" ? "hidden" : "visible"}}>
          <h4>
            {/* <img alt="icon" src={doller_sign} />  */}$
            {planeData?.planType == "Contact" ? "-" : planeData?.price}
          </h4>
          <span>/{planeData?.planDuration}</span>
        </div>

        {planeData?.planType == "Contact" ? (
          <a
            target="_blank"
            href={`mailto:${planeData?.email}`}
            rel="noreferrer"
            className="d-inline-block w-100 mt-32"
          >
            <Ripples color="#004ac340" during={1200}>
              <Button variant="" className="purchase_btn">
                Contact Us
              </Button>
            </Ripples>
          </a>
        ) : (
          <div className="mt-32">
            <Ripples color="#004ac340" during={1200}>
              <Link to={process.env.REACT_APP_REGISTRATION_URL} target="_blank">
                <Button variant="" className="purchase_btn">
                  Purchase now
                </Button>
              </Link>
            </Ripples>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlanCard;
