import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import goback from "../../assets/img/goback.svg";
import { Button, Card, CardHeader } from "react-bootstrap";
import Arrow_Swirl from "../../assets/img/Arrow_Swirl.svg";
import { useNavigate } from "react-router-dom";
import {
  getAccountManagementAsync,
  getAccountManagementResponse,
} from "../../service/slices/accountManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import $ from "jquery";

function AccountManagement(props) {
  const navigate = useNavigate();
  const _getAccountManagementResponse = useSelector(
    getAccountManagementResponse
  );
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFooterArrived, setisfooterarrived] = useState(false);

  const handleClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getAccountManagementAsync());
  }, []);

  useEffect(() => {
    // setIsLoading(_getAccountManagementResponse?.isLoading);
    if (_getAccountManagementResponse?.response?.status == 200) {
      setTopics(_getAccountManagementResponse?.response?.data);
    }
  }, [_getAccountManagementResponse]);

  const getHeadings = (decodeData) => {
    const parser = new DOMParser();

    // Parse the HTML content
    const doc = parser.parseFromString(decodeData, "text/html");

    // Find all h1 elements in the parsed document
    const h1Elements = doc.querySelectorAll("h1");
    let headingList = [];
    h1Elements.forEach((h1Element, index) => {
      headingList.push({
        text: h1Element.textContent.trim(),
        id: `section-${index + 1}`,
      });
    });
    return headingList;
  };


  

  return (
    <section className="AccountManagement_section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link className="goback" onClick={handleClickBack}>
              <img alt="icon" src={goback} /> Go Back
            </Link>
            <h2 className="heading wow fadeInUp" data-wow-duration=".60s">
              Account <span>management</span>
            </h2>
            <p className="head_para wow fadeInUp" data-wow-duration=".60s">
              Manage your account effortlessly by looking at these support
              articles and resources to seek the help and make you understand
              about Clarity Client
            </p>
          </div>
        </div>

        {isLoading ? (
          <div
            className=""
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
            />
          </div>
        ) : (
          <div className="row">
            {topics.map((item) => (
              <div
                className="col-md-12"
                key={item._id}
                style={{ marginBottom: "40px" }}
              >
                
                <Card>
                  <CardHeader>{item.moduleName}</CardHeader>
                  <Card.Body>
                    {item.topics.map((topic) => (
                      <div key={topic._id} className="part_row">
                        <Card.Title>{topic.topicName}</Card.Title>
                        <div className="row">
                        {getHeadings(topic.description).map((h) =>
                          <div className="col-md-6">
                           <Link
                            to={`/account-management-detail?id=${item.moduleId}&topicId=${topic._id}`}
                          >
                          {h.text}
                          </Link>
                           
                          </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </div>
            ))}

            <div className="col-md-12">
              <div className="row down_sec_contact">
                <div className="col-md-12">
                  <h4 className="wow fadeInUp" data-wow-duration="1s">
                    wanna seek more <span>help?</span>
                  </h4>
                </div>

                <div className="col-md-6 mb-4 mb-md-0">
                  <div
                    className="down_mini_sec wow fadeInUp"
                    data-wow-duration="1s"
                  >
                    <h5>
                      Questions, Feedback, or Just Want to Say Hello? Reach Out
                      to Us!
                    </h5>
                    <button>
                      FAQ
                      <img alt="icon" src={Arrow_Swirl} />
                    </button>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="down_mini_sec two wow fadeInUp"
                    data-wow-duration="1s"
                  >
                    <h5>
                      If you're ever unsure, you can watch video tutorials to
                      easily understand{" "}
                    </h5>
                    <Link to={"/tutorials"}>
                      <button>
                        Watch Tutorials
                        <img alt="icon" src={Arrow_Swirl} />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AccountManagement;
