import React from "react";
import { Table } from "react-bootstrap";
import tick from "../../assets/img/tick-circle.svg";
import cross from "../../assets/img/close-circle2.svg";

function PlanTable(props) {
  const { plansData } = props;
  return (
    <div className="table_wrapper_upper">
      <div className="table_wrapper">
        <Table className={props.class}>
          <thead>
            <tr>
              {plansData?.plans?.map((plan) => {
                return (
                  <>
                    <th>
                      <div>{plan?.name}</div>
                    </th>
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {plansData?.comparison?.users?.map((data,i) => {
                return plansData?.plans[i].planType == "Contact" ? (
                  <td>Custom</td>
                ) : (
                  <td>{data}</td>
                );
              })}
            </tr>
            <tr>
              {plansData?.comparison?.permissionType?.map((data, i) => {
                return plansData?.plans[i].planType == "Contact" ? (
                  <td>Custom</td>
                ) : (
                  <td>{data}</td>
                );
              })}
            </tr>
            <tr>
              {plansData?.comparison?.exportType?.map((data, i) => {
                return plansData?.plans[i].planType == "Contact" ? (
                  <td>Custom</td>
                ) : (
                  <td>{data.toString().replace(',',' & ')}</td>
                );
              })}
            </tr>
            <tr>
              {plansData?.comparison?.codePrinting?.map((data,i) => {
                return plansData?.plans[i].planType == "Contact" ? (
                  <td>Custom</td>
                ) : (
                  <td>{data}</td>
                );
              })}
            </tr>
            <tr>
              {plansData?.comparison?.domainSetup?.map((data, i) => {
                return (
                  <td>
                    {plansData?.plans[i].planType == "Contact" ? (
                      "Custom"
                    ) : (
                      <img alt="icon" src={data ? tick : cross} />
                    )}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PlanTable;
