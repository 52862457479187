import React from "react";
import encryption from "../../assets/img/encryption.svg";
import payment_icon from "../../assets/img/payment_icon.svg";
import payment_mode from "../../assets/img/payment_mode.svg";
import fee from "../../assets/img/fee.svg";
function OtherCards(props) {
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
        <div className="othercard wow fadeInLeft" data-wow-duration="1.2s">
          <div>
            <img alt="icon" src={encryption} />
          </div>
          <div>
            <h4>Sensitive data and communication encryption</h4>
            <p>
            Card numbers are securely encrypted using AES-256, with decryption keys stored separately for added security.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
        <div className="othercard wow fadeInUp" data-wow-duration="1.2s">
          <div>
            <img alt="icon" src={payment_icon} />
          </div>
          <div>
            <h4>Accepted Payments Methods</h4>
            <img alt="icon" src={payment_mode} />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-4 mb-lg-0 mt-0 mt-lg-4 mt-xl-0">
        <div className="othercard wow fadeInRight" data-wow-duration="1.2s">
          <div>
            <img alt="icon" src={fee} />
          </div>
          <div>
            <h4>No Processing Fee</h4>
            <p>Enjoy cost savings and higher profitability with no processing fees, making transactions more affordable and efficient.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherCards;
