import React, { useEffect, useState } from "react";
import superpower from "../../assets/img/superpower.png";
import counterfiet_big from "../../assets/img/counterfiet_big.svg";
import custumer_trust from "../../assets/img/custumer_trust.svg";
import hussale_free from "../../assets/img/hussale_free.svg";
import tickCircle from "../../assets/img/tick-circle.svg";
import realtime_tracking from "../../assets/img/realtime_tracking.svg";
import repoting from "../../assets/img/repoting.svg";
import customization from "../../assets/img/customization.svg";
import data_integration from "../../assets/img/data_integration.svg";
import client_automated from "../../assets/img/client_automated.svg";
import automated_detection from "../../assets/img/automated_detection.svg";
import custumer_verification from "../../assets/img/custumer_verification.svg";
import delivering_success from "../../assets/img/delivering_success.svg";
import user from "../../assets/img/user.png";
import linkedin_2 from "../../assets/img/linkedin_2.svg";
import Facebook_2 from "../../assets/img/Facebook_2.svg";
import Instagram_2 from "../../assets/img/Instagram_2.svg";
import twitter_2 from "../../assets/img/twitter_2.svg";
import counterfiet_2 from "../../assets/img/counterfiet_2.svg";
import years from "../../assets/img/years.svg";
import client_count from "../../assets/img/client_count.svg";
import user_count from "../../assets/img/user_count.svg";
import { Button } from "react-bootstrap";
import Ripples from "react-ripples";
import { useSelector, useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import {
  getAboutusProfessionalsAsync,
  getAboutProfessionalsResponse,
  getCountAsync,
  getCountResponse,
} from "../../service/slices/aboutUsSlice";
import CountUp, { useCountUp } from 'react-countup';

function AboutUs(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [getCountData, setGetCountData] = useState({});
  const [getProfessionalsData, setGetProfessionalsData] = useState([]);
  const dispatch = useDispatch();
  const _getCountResponse = useSelector(
    (state) => state?.aboutus?.getCountResponse
  );
  const _getAboutProfessionalsResponse = useSelector(
    (state) => state?.aboutus?.getAboutProfessionalsResponse
  );

  useEffect(() => {
    // setIsLoading(true);
    setIsLoadingCount(true);
    dispatch(getCountAsync());
    dispatch(getAboutusProfessionalsAsync());
  }, []);

  useEffect(() => {
    if (_getCountResponse?.response?.status == 200) {
      setIsLoadingCount(_getCountResponse?.isLoading);
      setGetCountData(_getCountResponse?.response?.data);
    }
  }, [_getCountResponse]);

  useEffect(() => {
    if (_getAboutProfessionalsResponse?.response?.status == 200) {
      setIsLoading(_getAboutProfessionalsResponse?.isLoading);
      setGetProfessionalsData(_getAboutProfessionalsResponse?.response?.data);
    }
  }, [_getAboutProfessionalsResponse]);

  console.log(_getAboutProfessionalsResponse, "::::");

  useCountUp({
    ref: 'counter',
    // enableScrollSpy: true,
    scrollSpyOnce: true,
    scrollSpyDelay: 1500,
    duration: 5
  });
  return (
    <>
      <section className="aboutus_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeInUp"
                data-wow-duration=".70s"
                style={{ marginBottom: "80px" }}
              >
                We're Bringing Development <span>Superpowers</span> to Everyone
              </h2>
            </div>
            <div
              className="col-md-10 text-center offset-1"
              style={{ marginBottom: "122px" }}
            >
              <img alt="icon"
                className="sideimg wow fadeIn"
                data-wow-duration="1.5s"
                src={superpower}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeIn"
                data-wow-duration="1s"
                style={{ marginBottom: "20px" }}
              >
                Core <span>Behaviour</span>
              </h2>
            </div>
            <div
              className="col-md-8 text-center offset-0 offset-md-2"
              style={{ marginBottom: "12px" }}
            >
              <p className="head_para  wow fadeIn" data-wow-duration="1s">
                Our anti-counterfeit solution integrates advanced technology
                with best practices to provide robust and measurable protection
                for your products.
              </p>
            </div>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".70s"
                src={counterfiet_big}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet wow fadeInRight"
                data-wow-duration=".70s"
              >
                <h3 className="sub_heading mb-3">
                  Build an Anti <span>Counterfeit</span> Solution{" "}
                </h3>
                <p className="sub_para">
                  Our anti-counterfeit solution is designed to provide strong
                  protection for your products and enhance consumer trust. We
                  are ready to collaborate with you to address the threat of
                  counterfeiting and safeguard your valuable company assets.
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "144px" }}>
            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-2 order-lg-1"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet"
                style={{ paddingTop: "81px" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  Gain <span>Customer</span> Trust on Clients’ Brand
                </h3>
                <p
                  className="sub_para mr-2rem mb-0 wow fadeInLeft"
                  data-wow-duration=".70s"
                >
                  Here are some specific strategies to build trust in a brand
                  that offers a counterfeit solution.
                </p>
                <div className="tags_ui">
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Trust Seals and Logos
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".10s"
                  >
                    <img alt="icon" src={tickCircle} /> Guarantees and Warranties
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".20s"
                  >
                    <img alt="icon" src={tickCircle} /> Responsive Customer Support
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".20s"
                  >
                    <img alt="icon" src={tickCircle} /> Regular Updates
                  </span>
                  <span
                    className=" wow fadeInUp"
                    data-wow-duration=".70s"
                    data-wow-delay=".30s"
                  >
                    <img alt="icon" src={tickCircle} /> Product Verification
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 order-1 order-lg-2"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInRight"
                data-wow-duration=".70s"
                src={custumer_trust}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "144px" }}>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 mb-5 mb-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".70s"
                src={hussale_free}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <h3
                className="sub_heading wow fadeInRight"
                data-wow-duration=".70s"
                style={{ marginBottom: "0" }}
              >
                Hassle free system to manage the <span>organisation</span>
              </h3>

              <div className="row wow fadeIn" data-wow-duration="2s">
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={realtime_tracking} />
                    <h3>Real-time Tracking</h3>
                    <p>
                      Track products in real-time, from production to consumer
                      verification, for immediate response to counterfeit risks.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={repoting} />
                    <h3>Reporting and Analytics</h3>
                    <p>
                      Gain actionable insights with built-in reporting and
                      analytics tools to identify counterfeit trends.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={customization} />
                    <h3>Customization</h3>
                    <p>
                      Tailor the system to match your organization's unique
                      counterfeit solution requirements and processes.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="innersection_hussle">
                    <img alt="icon" src={data_integration} />
                    <h3>Data Integration</h3>
                    <p>
                      Seamlessly incorporate crucial data sources to efficiently
                      enhance the performance of your counterfeit solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "144px" }}>
            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-2 order-lg-1"
              style={{ marginBottom: "0" }}
            >
              <h3
                className="sub_heading wow fadeInLeft"
                data-wow-duration=".70s"
                style={{ marginBottom: "0" }}
              >
                Leading <span>clients</span> towards an automated era
              </h3>
              <p
                className="sub_para wow fadeInLeft"
                data-wow-duration=".70s"
                style={{ marginBottom: "35px" }}
              >
                Our solution integrates cutting-edge technology with best
                practices to provide robust protection for your products.
              </p>

              <div className="row wow fadeIn" data-wow-duration="2s">
                <div className="col-md-6 mb-5 mb-md-0">
                  <div className="d-flex align-items-start">
                    <img alt="icon" src={automated_detection} />
                    <div className="client_automated_inner">
                      <h3>
                        Automated <br /> Detection
                      </h3>
                      <p>
                        Our technology allows for automatic detection of
                        counterfeit products, saving your company time and
                        resources.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-start">
                    <img alt="icon" src={custumer_verification} />
                    <div className="client_automated_inner">
                      <h3>
                        Consumer <br /> Verification
                      </h3>
                      <p>
                        Provide a mobile application that allows consumers to
                        easily verify the authenticity of your products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0 order-1 order-lg-2 mb-5 mb-lg-0"
              style={{ marginBottom: "0" }}
            >
              <div className="d-flex">
                <img alt="icon"
                  className="sideimg wow fadeInRight"
                  data-wow-duration=".7s"
                  src={client_automated}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div
              className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-2 offset-lg-0"
              style={{ marginBottom: "0" }}
            >
              <img alt="icon"
                className="sideimg wow fadeInLeft"
                data-wow-duration=".7s"
                src={delivering_success}
              />
            </div>

            <div
              className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
              style={{ marginBottom: "0" }}
            >
              <div
                className="innerbox_counterfiet"
                style={{ paddingTop: "173px" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInUp"
                  data-wow-duration=".7s"
                >
                  Delivering Success through <span>Clarity</span> Support
                </h3>
                <p className="sub_para wow fadeInUp" data-wow-duration=".9s">
                  At Clarity Support, we're dedicated to helping your business
                  reach new heights. Our comprehensive support solutions are
                  designed to be your trusted partner in achieving success
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h2
                className="heading wow fadeInUp"
                data-wow-duration=".7s"
                style={{ marginBottom: "20px", marginTop: "160px" }}
              >
                Meet our <span>professionals</span>
              </h2>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 offset-0 offset-md-2 offset-lg-3">
              <p
                className="head_para wow fadeInUp"
                data-wow-duration=".9s"
                style={{ marginBottom: "42px" }}
              >
                Comprehensive Support: Expert Guidance Every Step, Ensuring Your
                Success Through Challenges and Milestones Together.
              </p>
            </div>

            {getProfessionalsData?.length && !isLoading ? (
              getProfessionalsData?.map((Professional) => {
                return (
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div
                      className="customCard_prof wow fadeInUp"
                      data-wow-duration=".7s"
                    >
                      <div className="img_box">
                        <img alt="icon"
                          width="100%"
                          height="190px"
                          style={{ objectFit: "cover" }}
                          src={Professional?.profile}
                        />
                      </div>
                      <div className="content_box">
                        <h2>{Professional?.designation}</h2>
                        <h1>{`${Professional?.firstName} ${Professional?.lastName}`}</h1>
                        <p style={{ wordBreak: "break-all" }}>
                          {Professional?.bio}
                        </p>
                        <ul>
                          <li>
                            <a href={Professional?.linkedInUrl} target="_blank">
                              <img alt="icon" src={linkedin_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.facebookUrl} target="_blank">
                              <img alt="icon" src={Facebook_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.intagramUrl} target="_blank">
                              <img alt="icon" src={Instagram_2} />
                            </a>
                          </li>
                          <li>
                            <a href={Professional?.twitterUrl} target="_blank">
                              <img alt="icon" src={twitter_2} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className=""
                style={{
                  height: "100vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
                />
              </div>
            )}

            <div
              className="col-md-12 text-center wow fadeInUp"
              data-wow-duration=".7s"
              style={{ paddingTop: "42px", paddingBottom: "80px" }}
            >
              <Ripples color="#004ac340" during={1200}>
                <Button variant="" className="joinus_btn">
                  Join with us
                </Button>
              </Ripples>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus_wrapper countSection">
        <div className="container">
          {Object.keys(getCountData).length && !isLoadingCount ? (
            <div className="row">
              <div
                className="col-md-12 col-lg-6 mb-3 mb-lg-0"
                style={{ marginBottom: "0" }}
              >
                <h3
                  className="sub_heading mb-3 wow fadeInUp"
                  data-wow-duration=".7s"
                >
                  Our <span>Count</span>
                </h3>
                <p className="sub_para wow fadeInUp" data-wow-duration=".9s">
                  At Clarity Support, we're dedicated to helping your business
                  reach new heights. Our comprehensive support solutions are
                  designed to be your trusted partner in achieving success
                </p>
              </div>
              <div className="col-md-12 col-lg-6" style={{ marginBottom: "0" }}>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={counterfiet_2} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalCounterFeit} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalCounterFeit} />
                          <span>+</span>
                        </h2>
                        <p>Counterfeit Reported</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={years} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalYears}  */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalYears} />
                          <span>Years</span>
                        </h2>
                        <p>Of Services</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={client_count} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalClient} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalClient} />
                          <span>+</span>
                        </h2>
                        <p>Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="counter_box wow fadeIn"
                      data-wow-duration="1.5s"
                    >
                      <img alt="icon" src={user_count} />
                      <div>
                        <h2>
                          {/* {getCountData?.totalUsers} */}
                          <CountUp className="count_number" enableScrollSpy scrollSpyOnce end={getCountData?.totalUsers} />
                          <span>+</span>
                        </h2>
                        <p>Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AboutUs;
