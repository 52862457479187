import React, { useEffect, useState } from "react";
import PlanCard from "./PlanCard";
import { Accordion, Form } from "react-bootstrap";
import PlanTable from "./PlanTable";
import SideTable from "./SideTable";
import AccordionBox from "./AccordionBox";
import OtherCards from "./OtherCards";
import { useDispatch, useSelector } from "react-redux";
import { getFaqsAsync, getFaqsResponse } from "../../service/slices/faqsSlice";
import { ColorRing } from "react-loader-spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import {
  getPlanesAsync,
  getPlanesResponse,
} from "../../service/slices/planesSlice";
function PricingPurchase(props) {
  const [faqsData, setFaqsData] = useState([]);
  const [planesData, setPlanesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [planType, setPlanType] = useState("Monthly");
  const _getFaqsResponse = useSelector(getFaqsResponse);
  const _getPlanesResponse = useSelector(getPlanesResponse);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const getPlanesCalling = () => {
    setIsLoading(true);
    const val = isChecked == false ? "Monthly" : "Annual";
    dispatch(
      getPlanesAsync({
        type: val,
      })
    );
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getFaqsAsync({
        users: "Web",
        sortBy: "priority",
        order: "ASC",
      })
    );
    getPlanesCalling();
  }, []);
  useEffect(() => {
    if (_getFaqsResponse?.response?.status == 200) {
      setIsLoading(_getFaqsResponse?.isLoading);
      setFaqsData(_getFaqsResponse?.response?.data);
    }
  }, [_getFaqsResponse]);
  useEffect(() => {
    if (_getPlanesResponse?.response?.status == 200) {
      setIsLoading(_getPlanesResponse?.isLoading);

      setPlanesData(_getPlanesResponse?.response?.data);
    }
  }, [_getPlanesResponse]);
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    setIsChecked(isChecked);
    console.log(`Switch is ${isChecked ? "on" : "off"}`);
  };

  useEffect(() => {
    if (isChecked == true) {
      const AnnualData = planesData?.plans?.map((plan) => {
        return {
          ...plan,
          planDuration: "Annual",
          price: Math.round(
            plan?.price * 12 -
              ((plan?.price * 12) / 100) * (planesData?.discount || 0)
          ),
        };
      });
      setPlanesData({ ...planesData, plans: AnnualData });
    } else {
      if (_getFaqsResponse?.response?.status == 200) {
        setPlanesData(_getPlanesResponse?.response?.data);
      }
    }
  }, [isChecked]);

  return (
    <>
      {!isLoading ? (
        <section className={props.class}>
          <div className="container" style={{overflow: "hidden"}}>
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>
                Get Clarity on Counterfeit <span>Products</span>
                </h1>
                <p className="caption">
                Become a Clarity Client: Select a subscription that suits your needs.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="switch_box">
                  <p>Monthly</p>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      onChange={handleSwitchChange}
                      checked={isChecked}
                    />
                  </Form>
                  <p>
                    Yearly <span>{planesData?.discount || 0}% OFF</span>
                  </p>
                </div>
              </div>
            </div>









            <div className="row">
         
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >

{planesData?.plans?.map((plan) => {
                  return (
                    <SwiperSlide>
                      <PlanCard
                        planname="Free"
                        price="0"
                        class="custom_card wow fadeInRight"
                        planeData={plan}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>

          

            <Accordion
              defaultActiveKey="0"
              className="customAccordian"
              style={{ marginTop: "150px" }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="text">Hide</span>{" "}
                  <span className="text">Show</span> Comparison
                </Accordion.Header>
                <Accordion.Body>
                  {planesData?.plans?.length ? (
                    <div className="row">
                      <div className="col-md-12">
                      </div>
                      <div className="col-md-3">
                        <SideTable />
                      </div>
                      <div className="col-md-9">
                        <PlanTable class="PlanTable" plansData={planesData} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="row" style={{ marginTop: "96px" }}>
              <OtherCards />
            </div>
            <div className="row">
              <div
                className="col-md-12 text-center"
                style={{ marginTop: "120px", marginBottom: "64px" }}
              >
                <h1
                  className="wow fadeInUp"
                  data-wow-duration=".50s"
                  data-wow-delay=".30s"
                >
                  Frequently Asked <span>Questions</span>
                </h1>
                <p
                  className="caption wow fadeInUp"
                  data-wow-duration=".50s"
                  data-wow-delay=".50s"
                >
                  Everything You Need to Know About Our Services, Policies, Customization, 
                  Support, and Payment Options.
                </p>
              </div>

              <div className="col-md-12 wow fadeInUp" data-wow-duration="1s">
                <AccordionBox faqsData={faqsData} />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          />
        </div>
        
      )}
      
    </>
  );
}

export default PricingPurchase;
