import { useState, useEffect } from "react";
import whitearrowleft from "../../assets/img/whitearrowleft.svg";
import share_job from "../../assets/img/sharejob.svg";
import choose_icon from "../../assets/img/chooseicon.svg";
import popup_close from "../../assets/img/popupclose.svg";
import Instagram from "../../assets/img/Instagramshare.svg";
import linkdin from "../../assets/img/linkedinshare.svg";
import facebook from "../../assets/img/Facebookshare.svg";
import twitter from "../../assets/img/Twittershare.svg";
import Ripples from "react-ripples";
import { Link, useSearchParams } from "react-router-dom";
import Carousel from "react-grid-carousel";
import Form from "react-bootstrap/Form";
import { Button } from "bootstrap-4-react/lib/components";
import Modal from "react-bootstrap/Modal";
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import employeeIcon from "../../assets/img/employeeIcon.svg";
import logo from "../../assets/img/logo_without_text.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import {
  getJobAsync,
  getJobResponse,
  applyJobAsync,
  applyJobResponse,
  resetAsync
} from "../../service/slices/careersSlice";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";

function CareersDetail(props) {
  const _getJobResponse = useSelector(getJobResponse);
  const _applyJobResponse = useSelector(applyJobResponse);
  const dispatch = useDispatch();
  const [job, setJob] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isApplying, setApplying] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [value, setValue] = useState();

  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(-1);
  };

  const applyJob = (e) => {
    setApplying(true);
    dispatch(applyJobAsync(application));
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: "",
  });

  const [application, setApplication] = useState({
    jobId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: "",
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getJobAsync({ jobId: id }));
    }
  }, []);

  useEffect(() => {
    setIsLoading(_getJobResponse?.isLoading);
    if (_getJobResponse?.response?.status == 200) {
      setJob(_getJobResponse?.response?.data);
    } else if (_getJobResponse?.response?.status == 400) {
      toast.error("No job found");
    }
  }, [_getJobResponse]);

  useEffect(() => {
    setApplying(_applyJobResponse?.isLoading);
    if (_applyJobResponse?.response?.status == 200) {
      handleClose();
      toast.success(_applyJobResponse?.response?.message);
      dispatch(resetAsync({}));
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        resume: "",
      });
      setApplication({
        jobId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        resume: "",
      });
    } else if (_applyJobResponse?.response?.status == 400) {
      toast.error(_applyJobResponse?.response?.message);
      dispatch(resetAsync({}));
    }
  }, [_applyJobResponse]);

  useEffect(() => {
    if (job) {
      setApplication({ ...application, jobId: job._id });
    }
  }, [job]);

  const onChange = (value, type) => {
    if (type == "firstName") {
      if (value == "") {
        setErrors({ ...errors, firstName: "Please enter your first name" });
        setApplication({ ...application, firstName: "" });
      } else {
        setErrors({ ...errors, firstName: "" });
        setApplication({ ...application, firstName: value });
      }
    } else if (type == "lastName") {
      if (value == "") {
        setErrors({ ...errors, lastName: "Please enter your last name" });
        setApplication({ ...application, lastName: "" });
      } else {
        setErrors({ ...errors, lastName: "" });
        setApplication({ ...application, lastName: value });
      }
    } else if (type == "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      if (value == "") {
        setErrors({ ...errors, email: "Please enter your email" });
        setApplication({ ...application, email: "" });
      } else if (!isValidEmail) {
        setErrors({ ...errors, email: "Please enter a valid email" });
        setApplication({ ...application, email: "" });
      } else {
        setErrors({ ...errors, email: "" });
        setApplication({ ...application, email: value });
      }
    } else if (type == "phone") {
      const phoneRegex = /^\?\d+$/;
      // const isValidPhoneNumber = phoneRegex.test(value);
      if (value == "") {
        setErrors({ ...errors, phone: "Please enter your phone no." });
        setApplication({ ...application, phone: "" });
      } else if (value && value.length < 8 ) {
        setErrors({ ...errors, phone: "Please enter a valid phone no." });
        setApplication({ ...application, phone: "" });
      } else {
        setErrors({ ...errors, phone: "" });
        setApplication({ ...application, phone: value });
      }
    } else if (type == "resume") {
      const file = value.target.files[0];
      setErrors({ ...errors, resume: "" });
      setApplication({ ...application, resume: file });
    }
  };

  const getAdditional = (item) => {
    if (
      item?.additionalInfoTitle != null &&
      item?.additionalInfoDescription != null
    ) {
      return (
        <>
          <h3>{item?.additionalInfoTitle}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: item?.additionalInfoDescription,
            }}
          ></div>
        </>
      );
    } else {
      return "";
    }
  };

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return true;
      }
    }
    return false;
  };

  const handleLinkedin = () => {};

  return (
    <>
      {isLoading ? (
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          /> */}
        </div>
      ) : (
        <>
          <section className="careers_detail_one position-relative">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <Link to={"/careers"}>
                    <button className="go_back" onClick={handleClickBack}>
                      <img alt="icon" src={whitearrowleft} /> Go Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="careers_detail_two">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div
                    className="card_wrapper wow fadeInUp"
                    data-wow-duration=".70s"
                  >
                    <div className="card_job_detail">
                      <h3>{job?.jobTitleId?.jobTitle}</h3>
                      <div className="d-flex desktop_buttons">
                        <button className="share_btn" onClick={handleShow2}>
                          <img alt="icon" src={share_job} />
                        </button>
                        {job?.linkedin && job?.linkedin != "" && (
                          <button
                            className="apply_job"
                            style={{ marginRight: "20px" }}
                            onClick={() => window.open(job?.linkedin, "_blank")}
                          >
                            Apply on LinkedIn
                          </button>
                        )}
                        <button className="apply_job" onClick={handleShow}>
                          Apply now
                        </button>
                      </div>
                    </div>
                    <div className="card_job_detail mb-0">
                      <div>
                        <h5>JOB ID</h5>
                        <h6>{job?.jobTitleId?.jobId} </h6>
                      </div>
                      <div>
                        <h5>Location</h5>
                        <h6>
                          {job?.city}, {job?.country}
                        </h6>
                      </div>
                      <div>
                        <h5>Job Type</h5>
                        <h6>{job?.jobTitleId?.jobType}</h6>
                      </div>
                      <div>
                        <h5>Categories</h5>
                        <h6>{job?.jobTitleId?.jobCategory}</h6>
                      </div>
                    </div>
                    <div className="mobile_buttons">
                      <div className="d-flex">
                        <button className="share_btn" onClick={handleShow2}>
                          <img alt="icon" src={share_job} />
                        </button>
                        {job?.linkedin && job?.linkedin != "" && (
                          <button
                            className="apply_job"
                            style={{ marginRight: "20px" }}
                            onClick={() => window.open(job?.linkedin, "_blank")}
                          >
                            Apply on Linkedin
                          </button>
                        )}
                        <button className="apply_job" onClick={handleShow}>
                          Apply now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="careers_detail_three">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h3>Description</h3>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{ __html: job?.jobDescription }}
                    ></div>
                  </p>

                  <h3>Job Location</h3>
                  <p>
                    {job?.address}, {job?.city}, {job?.country}
                  </p>

                  <h3>Basic Qualifications</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job?.basicQualifications,
                    }}
                  ></div>

                  <h3>Preferred Qualifications</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job?.preferredQualifications,
                    }}
                  ></div>

                  {getAdditional(job)}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <Link to={"/careers"}>
                    <button className="openings_btn">
                      See All job Openings
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Modal className="applyjob_modal" show={show} onHide={handleClose}>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {!isApplying ? (
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        onChange(e.target.value, "firstName");
                      }}
                      type="text"
                      placeholder="Your first name"
                    />
                    <Form.Text className="text-muted">
                      {errors.firstName == ""
                        ? "This must be filled with your first name"
                        : errors.firstName}
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        onChange(e.target.value, "lastName");
                      }}
                      type="text"
                      placeholder="Your last name"
                    />
                    <Form.Text className="text-muted">
                      {errors.lastName == ""
                        ? "This must be filled with your last name"
                        : errors.lastName}
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        onChange(e.target.value, "email");
                      }}
                      type="email"
                      placeholder="Your email"
                    />
                    <Form.Text className="text-muted">
                      {errors.email == ""
                        ? "This must be filled with your email address"
                        : errors.email}
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone number</Form.Label>
                    {/* <Form.Control
                    onChange={(e) => {
                      onChange(e, "phone");
                    }}
                    type="text"
                    placeholder="Your phone number"
                  /> */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={value}
                      onChange={(e) => {
                        onChange(e, "phone");
                      }}
                      className="form-control phone_country"
                      defaultCountry="AU"
                      international = {true}
                    />
                    <Form.Text className="text-muted">
                      {errors.phone && errors.phone}
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Be sure to include an updated resume
                    </Form.Label>
                    <div className="choose_btn position-relative">
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => {
                          onChange(e, "resume");
                        }}
                      />
                      <div className="d-flex align-items-center">
                        <img alt="icon" src={choose_icon} />
                        <h4>Upload resume</h4>
                      </div>
                    </div>
                    <Form.Text className="text-muted">
                      {errors.resume == ""
                        ? "DOC, DOCX, PDF (2 MB)"
                        : errors.resume}
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Ripples color="#ffffff47" during={1200}>
                    <Button
                      disabled={
                        !(isEmpty(errors) && !isEmpty(application)) ||
                        isApplying
                      }
                      className="submit"
                      onClick={applyJob}
                      varient=""
                      style={{
                        backgroundColor:
                          !(isEmpty(errors) && !isEmpty(application)) ||
                          isApplying
                            ? "#677487"
                            : "",
                      }}
                    >
                      {isApplying ? "Applying" : "Apply now"}
                    </Button>
                  </Ripples>
                </div>
              </div>
            ) : (
              <div
                className=""
                style={{
                  marginTop: "200px",
                  height: "300px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    style={{ height: "30px", width: "40px" }}
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="three col">
                  <div className="loader" id="loader-4">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
        <div className="closeModal" onClick={handleClose}>
          <img alt="icon" src={popup_close} />
        </div>
      </Modal>

      <Modal
        className="applyjob_modal sharemodal"
        show={show2}
        onHide={handleClose2}
      >
        <div className="share_modal">
          <h3>Share</h3>
          <div className="closeModal" onClick={handleClose2}>
            <img alt="icon" src={popup_close} />
          </div>
        </div>
        <Modal.Body>
          <h4>Share this link via</h4>
          <div className="icons_box">
            <Link
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `https://webstage.clarity.codes/careers-detail/${id}`
                );
                toast.success("Link copied success.");
              }}
            >
              <img alt="icon" src={linkdin} />
            </Link>
            <Link to={"#"}>
              <img alt="icon" src={Instagram} />
            </Link>
            <Link to={"#"}>
              <img alt="icon" src={facebook} />
            </Link>
            <Link to={"#"}>
              <img alt="icon" src={twitter} />
            </Link>
          </div>
          <h4>or copy link</h4>
          <div className="newsletterBox">
            <Form.Control
              placeholder="Share Link"
              value={`https://webstage.clarity.codes/careers-detail/${id}`}
              readOnly={true}
            />
            <div className="react-ripples-box">
              <Ripples color="#ffffff47" during={1200}>
                <Button
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      `https://webstage.clarity.codes/careers-detail/${id}`
                    );
                    toast.success("Link copied success.");
                    handleClose2();
                  }}
                  variant=""
                >
                  Copy
                </Button>
              </Ripples>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CareersDetail;
