import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import goback from "../../assets/img/goback.svg";
import bg_gradient from "../../assets/img/bg_gradient.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrivacyPolicyAsync,
  getPrivacyPolicyResponse,
} from "../../service/slices/teamsConditionSlice";
import { ColorRing } from "react-loader-spinner";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [decodedData, setDecodeData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [isFooterArrived, setisfooterarrived] = useState(false);

  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(-1);
  }

  // useEffect(() => window.scrollTo({ offsetTop: 1000, behavior: "smooth" }), []);

  const _getPrivacyPolicyResponse = useSelector(getPrivacyPolicyResponse);

  useEffect(() => {
    if (!_getPrivacyPolicyResponse?.response?.data?.value) {
      setIsLoading(true);
      dispatch(getPrivacyPolicyAsync({}, (res) => {}));
    }
  }, []);

  useEffect(() => {
    if (_getPrivacyPolicyResponse?.response?.data?.[0]?.value) {
      const details = _getPrivacyPolicyResponse?.response?.data?.[0]?.value;
      const decodeData = decodeURIComponent(escape(window.atob(details)));
      // Create a DOMParser
      const parser = new DOMParser();

      // Parse the HTML content
      const doc = parser.parseFromString(decodeData, "text/html");

      // Find all h1 elements in the parsed document
      const h1Elements = doc.querySelectorAll("h1");
      findHeadings(h1Elements);
      let count = 0;
      let dataArr = decodeData?.split("<h1>").map((val, i) => {
        if (val.includes("h1")) {
          count++;
          return `<h1 id="section-${count}">${val}`;
        }
        return val;
      });
      setDecodeData(dataArr);
      setIsLoading(_getPrivacyPolicyResponse?.isLoading);
    }
  }, [_getPrivacyPolicyResponse]);

  const findHeadings = (h1Elements) => {
    let headingList = [];
    h1Elements.forEach((h1Element, index) => {
      headingList.push({
        text: h1Element.textContent.trim(),
        id: `section-${index + 1}`,
      });
    });
    setHeadings([...headingList]);
  };

  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const scrollToSection = (id) => {
    const $element = $("#" + id);
    if ($element.length) {
      console.log($element);
      $("html, body").animate(
        {
          scrollTop: $element.offset().top - 150,
        },
        800
      );
    }
  };
  $(window).scroll(function () {
    var scrollPosition = $(window).scrollTop();
    var bodyHeight = $("body").height();
    var footerHeight = 1400;

    const activeLi = $('li.isCurrent');

    if (activeLi.length > 0) {
      const nextLi = activeLi.next('li');
      if (nextLi.length > 0) {
        const scrollPosition = nextLi.offset().top - $('ul').offset().top - nextLi.height();
        $('ul').scrollTop($('ul').scrollTop() + scrollPosition);
      }
    }
    // console.log(bodyHeight - 444);
    console.log(scrollPosition, "scroll position", bodyHeight, "body heght");
    if (scrollPosition > bodyHeight - footerHeight) {
      console.log("hello");
      setisfooterarrived(true);
    }
    else{
      setisfooterarrived(false)
    }
  });

  useEffect(() => {
    if (isFooterArrived) {
      console.log("hello");
      $(".sidebar_wrapper").css({ position: "static" });
      $(".contant").css({ "margin-left": "0em" });
    } else {
         $(".sidebar_wrapper").css({ position: "" });
      $(".contant").css({ "margin-left": "30em" });
      console.log("hello again");
    }
  }, [isFooterArrived]);
  return (
    <>
      {!isLoading ? (
        <section className="termcondition_page">
          <div className="container">
            <div className="row">

              
              {/* <div className="col-md-12">
                <Link className="goback" to={"#"}>
                  <img alt="icon" src={goback} /> Go Back
                </Link>
                <h2 className="heading">
                  Privacy <span>Policy</span>
                </h2>
                <div className="datebox">
                  <p>Effective Date:</p>
                  <h3>April 1, 2021</h3>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="scroll_wrapper">
                  <div className="sidebar_wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <Link className="goback wow fadeInUp" data-wow-duration=".50s" onClick={handleClickBack}>
                          <img alt="icon" src={goback} /> Go Back
                        </Link>
                        <h2 className="heading wow fadeInUp" data-wow-duration=".60s">
                          Privacy <span>Policy</span>
                        </h2>
                        <div className="datebox wow fadeInUp" data-wow-duration=".70s">
                          <p>Effective Date:</p>
                          <h3>April 1, 2021</h3>
                        </div>
                      </div>
                    </div>

                    <div className="sidebar wow fadeInUp" data-wow-duration=".80s">
                      {/* <ul>
                      <li>
                        <Link to={"#"}>Introduction</Link>
                      </li>
                      <li>
                        <Link to={"#"}>Clarity Codes – General Information</Link>
                      </li>
                    </ul> */}
                      <Scrollspy
                        className="scrollspy"
                        items={headings.map((heading) => heading.id)}
                        currentClassName="isCurrent"
                      >
                        {headings.map((value) => (
                          <li key={value.id}>
                            <Link
                              to={`#${value.id}`}
                              onClick={() => scrollToSection(value.id)}
                            >
                              {value.text}
                            </Link>
                          </li>
                        ))}
                      </Scrollspy>
                    </div>
                  </div>
                  <div className="contant" ref={contentRef}>
                    {decodedData.map((value, i) => {
                      return (
                        <>
                          <div
                            dangerouslySetInnerHTML={{ __html: value }}
                          ></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img alt="icon" className="bg_gradient" src={bg_gradient} />
        </section>
      ) : (
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          />
        </div>
      )}
    </>
  );
}

export default PrivacyPolicy;
