import React from 'react';
import learnmorearrow from "../../assets/img/learnmore-arrow.svg";
import { Button } from "react-bootstrap";

function Card(props) {
    return (
        <div className="customCard">
              <div className="d-flex align-items-center">
                <img alt="icon" src={props.icon} />
                <h5>{props.title}</h5>
              </div>
              <p>
                {props.content}
              </p>
              <Button
                href="URL"
                variant=""
                // onClick={() => onClickOpenVacancy(id)}
              >Learn More <img alt="icon" src={learnmorearrow} /></Button>
            </div>
    );
}

export default Card;