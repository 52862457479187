import React, { useState, useEffect } from "react";
import carrer_hero from "../../assets/img/carrer_hero.png";
import open_positions from "../../assets/img/open_positions.svg";
import employe_srtip from "../../assets/img/employe_srtip.svg";
import work_culture from "../../assets/img/work_culture.png";
import work_culture1 from "../../assets/img/work_culture1.png";
import work_culture2 from "../../assets/img/work_culture2.png";
import location_job from "../../assets/img/location_job.svg";
import job_alert from "../../assets/img/job_alert.svg";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import Carousel from "react-grid-carousel";
import Form from "react-bootstrap/Form";
import { Button } from "bootstrap-4-react/lib/components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import {
  getJobsAsync,
  getJobsResponse,
} from "../../service/slices/careersSlice";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";

function Careers(props) {
  const _getJobsResponse = useSelector(getJobsResponse);
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [staff, setStaff] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState();
  const [location, setLocation] = useState();
  const [fontSize, setFontSize] = useState(
    window.innerWidth <= 1000
      ? "Search jobs by title, category, dep..."
      : "Search jobs by title, category, department, etc."
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getJobsAsync());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setFontSize(
        window.innerWidth <= 1000
          ? "Search jobs by title, category, dep..."
          : "Search jobs by title, category, department, etc."
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(_getJobsResponse?.isLoading);
    if (_getJobsResponse?.response?.status == 200) {
      setJobs(_getJobsResponse?.response?.data?.jobs);
      setStaff(_getJobsResponse?.response?.data?.staff);
    }
  }, [_getJobsResponse]);

  useEffect(() => {
    const params = {};
    if (query && query !== "") {
      params.search = query;
    }
    if (location && location !== "") {
      params.location = location;
    }
    if (query == "") {
      setIsLoading(true);
      dispatch(getJobsAsync(params));
    }
  }, [query]);

  useEffect(() => {
    const params = {};
    if (query && query !== "") {
      params.search = query;
    }
    if (location && location !== "") {
      params.location = location;
    }
    if (location == "") {
      setIsLoading(true);
      dispatch(getJobsAsync(params));
    }
  }, [location]);

  const onSearch = () => {
    setIsLoading(true);
    const params = {};
    if (query) {
      params.search = query;
    }
    if (location) {
      params.location = location;
    }
    dispatch(getJobsAsync(params));
  };

  return (
    <>
      <section className="help_support careers_page pb-0 position-relative">
        <div className="container">
          <div className="row row_one">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 position-relative mb-4 mb-lg-0">
              <h2 className="wow fadeInLeft" data-wow-duration="1s">
                Wanna <span>grow?</span> Run with us!
              </h2>
              <p
                className="wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".20s"
              >
                At Clarity, we're more than just a workplace; we're a community
                of innovators, dreamers, and doers. Join us to be a part of a
                team that's changing the world
              </p>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-6 col-xl-6 offset-0 offset-md-1 offset-lg-0 text-right position-relative">
              <div className="careers_hero">
                <img alt="icon" className="w-100 wow fadeInRight" src={carrer_hero} />
                <div
                  className="open_positions wow fadeIn"
                  data-wow-delay=".70s"
                >
                  <img alt="icon" src={open_positions} />
                  <div>
                    <h4>+{jobs.length > 0 ? jobs.length : "20"}</h4>
                    <p>Open positions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="hero_strip">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <img alt="icon" src={employe_srtip} />
                  <div className="innerbox">
                    <h3>+{staff ? staff : "10"}</h3>
                    <p>Employee</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="help_support careers_page pt-0">
        <div className="container overflow-hidden">
          <div className="row life_clarity">
            <div className="col-md-12">
              <h4>
                Life at <span>Clarity</span>
              </h4>
              <p>We believe in taking care of our team, which is why we</p>
            </div>

            <div className="col-md-12">
              {/* <Carousel
                cols={2}
                rows={1}
                gap={32}
                loop
                showDots={true}
                hideArrow={true}
              > */}

              <Swiper
                slidesPerView={2}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="slider_card wow fadeInRight">
                    <img alt="icon" src={work_culture1} />
                    <h4>Work Culture</h4>
                    <p>
                      Welcome to Life at Clarity We believe that a fulfilling
                      career goes beyond the job description. It's about the
                      people, the culture, and the experiences that make coming
                      to work exciting every day.
                    </p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider_card wow fadeInRight">
                    <img alt="icon" src={work_culture} />
                    <h4>Our People</h4>
                    <p>
                      Meet some of the brilliant minds who drive our success.
                      [Include photos and brief profiles of team members from
                      various roles and departments]. They're the reason why
                      we're proud to call this place our second home.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slider_card wow fadeInRight">
                    <img alt="icon" src={work_culture2} />
                    <h4>Work Culture</h4>
                    <p>
                      Welcome to Life at Clarity We believe that a fulfilling
                      career goes beyond the job description. It's about the
                      people, the culture, and the experiences that make coming
                      to work exciting every day.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
              {/* </Carousel> */}
            </div>
          </div>

          {isLoading ? (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              /> */}
            </div>
          ) : (
            <>
              <div id="find_jobs" className="row life_clarity m-0">
                <div className="col-md-12">
                  <h4 style={{ marginBottom: "40px" }}>
                    Find <span>Jobs</span>
                  </h4>
                </div>
              </div>
              <div className="row find_jobs_form">
                <div className="col-md-12">
                  <Form
                    // action={"#"}
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    // }}
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-3 mb-md-0">
                        <Form.Group
                          className="mb-0 position-relative"
                          controlId="formBasicEmail"
                        >
                          <Form.Control
                            type="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={fontSize}
                            // style={{ fontSize: '12px' }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3 mb-sm-0">
                        <Form.Group
                          className="mb-0 position-relative"
                          controlId="formBasicEmail"
                        >
                          <Form.Control
                            type="search"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Search by location"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <Form.Group className="mb-0" controlId="formBasicEmail">
                          <Button onClick={onSearch}>Search</Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="col-md-12">
                  <h3 className="sub_heading wow fadeInUp">
                    {jobs.length > 0 ? (
                      <>
                        Current job <span>Openings</span>
                      </>
                    ) : (
                      <>
                        No job <span>Openings</span>
                      </>
                    )}
                  </h3>
                </div>

                <div className="col-md-12">
                  {jobs.map((item) => (
                    <div className="job_list wow fadeInUp">
                      <h4>{item.jobTitleId.jobTitle}</h4>
                      <div className="d-flex align-items-center">
                        <img alt="icon" src={location_job} />
                        <p className="m-0">
                          {item.city} {item.country}
                        </p>
                      </div>
                      <Link to={`/careers-detail?id=${item._id}`}>
                        <button className="">Learn more</button>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <section className="job_alert_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-2 order-lg-1 d-flex align-items-center mt-5 mt-lg-0">
              <div className="wow fadeInLeft" data-wow-duration=".70s">
                <h4>
                  Wanna Receive <span>Job</span> Alerts?
                </h4>
                <p>
                  Whether you're actively seeking a new career or simply curious
                  about exciting opportunities, our job alerts can help you
                  discover the perfect match.
                </p>

                <div className="d-flex flex-wrap">
                  <Form.Group
                    className="mb-0 position-relative"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Your email address"
                    />
                  </Form.Group>
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Button>Subscribe</Button>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-6 offset-0 offset-sm-2 offset-lg-0 order-1 order-lg-2">
              <img alt="icon"
                className="wow fadeInRight"
                data-wow-duration=".70s"
                src={job_alert}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
