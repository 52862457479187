import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import goback from "../../assets/img/goback.svg";
import bg_gradient from "../../assets/img/bg_gradient.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamsConditionAsync,
  getTeamsConditionResponse,
} from "../../service/slices/teamsConditionSlice";
import { ColorRing } from "react-loader-spinner";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import * as moment from "moment-timezone";

function TermCondition(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [decodedData, setDecodeData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [date , setDate] = useState("");
  const [isFooterArrived, setisfooterarrived] = useState(false);
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(-1);
  }

  const _getTeamsConditionresponse = useSelector(getTeamsConditionResponse);

  useEffect(() => {
    if (!_getTeamsConditionresponse?.response?.data?.value) {
      // setIsLoading(true);
      dispatch(getTeamsConditionAsync({}, (res) => {}));
    }
  }, []);

  useEffect(() => {
    if (_getTeamsConditionresponse?.response?.data?.value) {
      setDate(_getTeamsConditionresponse?.response?.data?.updatedAt)
      const details = _getTeamsConditionresponse?.response?.data?.value;
      const decodeData = decodeURIComponent(escape(window.atob(details)));

      // Create a DOMParser
      const parser = new DOMParser();

      // Parse the HTML content
      const doc = parser.parseFromString(decodeData, "text/html");

      // Find all h1 elements in the parsed document
      const h1Elements = doc.querySelectorAll("h1");
      // Log the text content of each h1 element
      findHeadings(h1Elements);
      let count = 0;
      let dataArr = decodeData?.split("<h1>").map((val, i) => {
        if (val.includes("h1")) {
          count++;
          return `<h1 id="section-${count}">${val}`;
        }
        return val;
      });
      setDecodeData(dataArr);
      // setIsLoading(_getTeamsConditionresponse?.isLoading);
    }
  }, [_getTeamsConditionresponse]);

  const findHeadings = (h1Elements) => {
    let headingList = [];
    h1Elements.forEach((h1Element, index) => {
      headingList.push({
        text: h1Element.textContent.trim(),
        id: `section-${index + 1}`,
      });
    });
    setHeadings([...headingList]);
  };

  // const scrollToSection = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const scrollToSection = (id) => {
    const $element = $("#" + id);
    if ($element.length) {
      console.log($element);
      $("html, body").animate(
        {
          scrollTop: $element.offset().top - 150,
        },
        800
      );
    }
  };
  $(window).scroll(function () {
    var scrollPosition = $(window).scrollTop();
    var bodyHeight = $("body").height();
    var footerHeight = 1300;

    const activeLi = $('li.isCurrent');

    if (activeLi.length > 0) {
      const nextLi = activeLi.next('li');
      if (nextLi.length > 0) {
        const scrollPosition = nextLi.offset().top - $('ul').offset().top - nextLi.height();
        $('ul').scrollTop($('ul').scrollTop() + scrollPosition);
      }
    }
    // console.log(bodyHeight - 444);
    console.log(scrollPosition, "scroll position", bodyHeight, "body heght");
    if (scrollPosition > bodyHeight - footerHeight) {
      console.log("hello");
      setisfooterarrived(true);
    }
    else{
      setisfooterarrived(false)
    }
  });
  useEffect(() => {
    if (isFooterArrived) {
      console.log("hello");
      $(".sidebar_wrapper").css({ position: "static" });
      $(".contant").css({ "margin-left": "0em" });
    } else {
         $(".sidebar_wrapper").css({ position: "" });
      $(".contant").css({ "margin-left": "30em" });
      console.log("hello again");
    }
  }, [isFooterArrived]);

  return (
    <>
      {!isLoading ? (
        <>
          <section className="termcondition_page">
            <div className="container">
              <div className="row">
                {/* <div className="col-md-12">
                  <Link className="goback" to={"#"}>
                    <img alt="icon" src={goback} /> Go Back
                  </Link>
                  <h2 className="heading">
                    Term and <span>Conditions</span>
                  </h2>
                  <div className="datebox">
                    <p>Effective Date:</p>
                    <h3>April 1, 2021</h3>
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div className="scroll_wrapper">
                    <div className="sidebar_wrapper">
                      <div className="row">
                        <div className="col-md-12">
                          <Link className="goback wow fadeInUp" data-wow-duration=".50s"  onClick={handleClickBack}>
                            <img alt="icon" src={goback} /> Go Back
                          </Link>
                          <h2 className="heading wow fadeInUp" data-wow-duration=".60s">
                            Term and <span>Conditions</span>
                          </h2>
                          <div className="datebox wow fadeInUp" data-wow-duration=".70s">
                            <p>Effective Date:</p>
                            <h3>{ moment(date).format("MMMM D, YYYY")}</h3>
                          </div>
                        </div>
                      </div>

                      <div className="sidebar wow fadeInUp" data-wow-duration=".80s">
                        <Scrollspy
                          className="scrollspy"
                          items={headings.map((heading) => heading.id)}
                          currentClassName="isCurrent"
                        >
                          {headings.map((value) => (
                            <li key={value.id}>
                              <Link
                                to={`#${value.id}`}
                                onClick={() => scrollToSection(value.id)}
                              >
                                {value.text}
                              </Link>
                            </li>
                          ))}
                        </Scrollspy>
                      </div>
                    </div>
                    <div className="contant" ref={contentRef}>
                      {decodedData.map((value, i) => {
                        return (
                          <>
                            <div
                              dangerouslySetInnerHTML={{ __html: value }}
                            ></div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img alt="icon" className="bg_gradient" src={bg_gradient} />
          </section>
        </>
      ) : (
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
          />
        </div>
      )}
    </>
  );
}

export default TermCondition;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Scrollspy from "react-scrollspy";
// import goback from "../../assets/img/goback.svg";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getTeamsConditionAsync,
//   getTeamsConditionResponse,
// } from "../../service/slices/teamsConditionSlice";
// import { ColorRing } from "react-loader-spinner";

// function TermCondition(props) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [decodedData, setDecodeData] = useState("");
//   const [headings, setHeadings] = useState([]);
//   const dispatch = useDispatch();

//   const _getTeamsConditionresponse = useSelector(getTeamsConditionResponse);

//   console.log(_getTeamsConditionresponse);

//   useEffect(() => {
//     if (!_getTeamsConditionresponse?.response?.data?.value) {
//       setIsLoading(true);
//       dispatch(getTeamsConditionAsync({}, (res) => {}));
//     }
//   }, []);

//   useEffect(() => {
//     if (_getTeamsConditionresponse?.response?.data?.value) {
//       const details = _getTeamsConditionresponse?.response?.data?.value;
//       const decodeData = decodeURIComponent(escape(window.atob(details)));
//       console.log(decodeData);
//       // Create a DOMParser
//       const parser = new DOMParser();

//       // Parse the HTML content
//       const doc = parser.parseFromString(decodeData, "text/html");

//       // Find all h1 elements in the parsed document
//       const h1Elements = doc.querySelectorAll("h1");
//       // Log the text content of each h1 element
//       // h1Elements.forEach((h1Element, index) => {
//       //   console.log(`Heading ${index + 1}: ${h1Element.textContent.trim()}`);
//       //   setHeadings([...headings, h1Element.textContent.trim()]);
//       // });
//       findHeadings(h1Elements);
//       setDecodeData(decodeData);
//       setIsLoading(_getTeamsConditionresponse?.isLoading);
//     }
//   }, [_getTeamsConditionresponse]);

//   const findHeadings = (h1Elements) => {
//     let headingList = [];
//     h1Elements.forEach((h1Element, index) => {
//       console.log(`Heading ${index + 1}: ${h1Element.textContent.trim()}`);
//       headingList.push(h1Element.textContent.trim());
//     });
//     console.log(headingList, "::list");
//     setHeadings([...headingList]);
//   };

//   return (
//     <>
//       {!isLoading ? (
//         <>
//           <section className="termcondition_page">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-12">
//                   <Link className="goback" to={"#"}>
//                     <img alt="icon" src={goback} /> Go Back
//                   </Link>
//                   <h2 className="heading">
//                     Term and <span>Conditions</span>
//                   </h2>
//                   <div className="datebox">
//                     <p>Effective Date:</p>
//                     <h3>April 1, 2021</h3>
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="scroll_wrapper">
//                     <div className="sidebar">
//                       <Scrollspy
//                         className="scrollspy"
//                         items={[
//                           "section-1",
//                           "section-2",
//                           "section-3",
//                           "section-4",
//                           "section-5",
//                         ]}
//                         currentClassName="isCurrent"
//                       >
//                         {headings?.length
//                           ? headings?.map((value) => {
//                               return (
//                                 <li>
//                                   <Link to={"#section-1"}>{value}</Link>
//                                 </li>
//                               );
//                             })
//                           : ""}
//                       </Scrollspy>
//                     </div>
//                     <div className="contant">
//                       <section id="section-1">
//                         <div
//                           dangerouslySetInnerHTML={{ __html: decodedData }}
//                         ></div>
//                       </section>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </>
//       ) : (
//         <div
//           className=""
//           style={{
//             height: "100vh",
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <ColorRing
//             visible={true}
//             height="80"
//             width="80"
//             ariaLabel="blocks-loading"
//             wrapperStyle={{}}
//             wrapperClass="blocks-wrapper"
//             colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
//           />
//         </div>
//       )}
//     </>
//   );
// }

// export default TermCondition;
