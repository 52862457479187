import React from "react";
import SlideItem from "./SlideItem";
import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Client(props) {
  return (
    <section className={props.class}>
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <h4>
            Hear it Directly from Our <span>Global Users</span>
            </h4>
          </div>

          <div className="col-md-12">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              // loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
              <SwiperSlide>
                <SlideItem />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Client;
