import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import goback from "../../assets/img/goback.svg";
import { Button, Card, CardHeader, Modal } from "react-bootstrap";
import play_btn from "../../assets/img/play_btn.svg";
import click_video from "../../assets/img/click_video.svg";
import video_img from "../../assets/img/video_img.png";
import {
  getVideoTutorialsAsync,
  videoTutorialsResponse,
} from "../../service/slices/videoTutorialsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import * as moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import getThumbnailUrl from '@joegesualdo/get-youtube-video-thumbnail-url-js';


function VideoTutorials(props) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const _videoTutorialsResponse = useSelector(videoTutorialsResponse);
  const dispatch = useDispatch();
  const [videoTutorials, setVideoTutorials] = useState([]);
  const [video, setVideo] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (url) => {
    setVideo(url);
    setShow(true);
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getVideoTutorialsAsync());
  }, []);

  useEffect(() => {
    if (_videoTutorialsResponse?.response?.status === 200) {
      // setIsLoading(_videoTutorialsResponse?.isLoading);
      const videosWithThumbnails = _videoTutorialsResponse.response.data.map(video => {
        const pattern = /https:\/\/www.youtube.com\/embed\/([^?]+)/;
        const match = video.videoLink.match(pattern);
        if (match) {
          const thumbnailUrl = getThumbnailUrl(match[1], 'large');
          return { ...video, thumbnailUrl }; // Add thumbnailUrl property to the video object
        } else {
          return video;
        }
      });
      setVideoTutorials(videosWithThumbnails);
    }
  }, [_videoTutorialsResponse]);

  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(-1);
  }

  return (
    <>
      <section className="AccountManagement_section videopage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link className="goback"  onClick={handleClickBack}>
                <img alt="icon" src={goback} /> Go Back
              </Link>
              <h2 className="heading wow fadeInUp" data-wow-duration=".60s">Tutorials</h2>
              <p className="head_para wow fadeInUp" data-wow-duration=".60s">
                Follow our videos and articles to get friendly with this portal!
              </p>
            </div>
          </div>
          {!isLoading ? (
            <div className="row wow fadeIn" data-wow-duration="1.60s" style={{ marginBottom: "0" }}>
              {videoTutorials.map((item) => (
                <div
                  key={item._id}
                  className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="custom_card">
                    <div className="img_box">
                      <img alt="icon" src={item.thumbnailUrl} onClick={() => {
                            handleShow(item.videoLink);
                          }}/>
                    </div>
                    <div className="detail_box">
                      <h3>{item.videoName}</h3>
                      <p className="para">{item.videoDescription}</p>
                      <div className="btn_row">
                        <Button
                          variant=""
                          onClick={() => {
                            handleShow(item.videoLink);
                          }}
                        >
                          <img alt="icon" src={play_btn} />
                        </Button>
                        <p>
                          {moment.utc(item.updatedAt).format("MMM DD, YYYY")}
                        </p>
                        {/* <p>
                          <img alt="icon" src={click_video} /> 11 Min
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className=""
              style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#004ac3", "#346fcf", "#528ae6", "#346fcf", "#004ac3"]}
              />
            </div>
          )}
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className="video_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="550"
            src={video}
            title="Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoTutorials;
