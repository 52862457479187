import React, { useEffect, useState } from "react";
import address from "../../assets/img/address.svg";
import email from "../../assets/img/email.svg";
import phone from "../../assets/img/phone_contact.svg";
import Arrow_Swirl from "../../assets/img/Arrow_Swirl.svg";
import { Button, Form } from "react-bootstrap";
import FormControl from "../../components/contact/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { setContactAsync, resetAsync } from "../../service/slices/contactSlice";
import { toast } from "react-toastify";
import { getCountries, getStates } from "country-state-picker";
import { Link, Route, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import logo from "../../assets/img/logo_without_text.png";

function ContactUs(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [supportData, setSupportData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    country: "",
    state: "",
    details: "",
  });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    country: "",
    state: "",
    details: "",
  });
  const [contacts, setContact] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    const countriesData = getCountries();
    setCountries(countriesData);
    const contact = localStorage.getItem("contact");
    if (contact && contact != "") {
      setContact(JSON.parse(contact)[0]);
    }
  }, []);

  // const HandleChanges = (event) => {
  //   const {
  //     target: { name, value },
  //   } = event;
  //   if (name == "country") {
  //     getStateByCountry(value);
  //   }
  //   setSupportData({ ...supportData, [name]: value?.trimLeft() });

  //   // Validate the specific field
  //   const formData = {
  //     ...supportData,
  //     [name]: value?.trimLeft(),
  //   };

  //   const hasError = FormValidation(formData);

  //   // Update the specific error for the changed field
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: hasError ? errorsDetails[name] : "",
  //   }));
  // };

  const getStateByCountry = (value) => {
    console.log(value);
    const selectedCountry = countries?.filter(
      (country) => country?.name === value
    );
    const data = getStates(selectedCountry?.[0]?.code);
    setStates(data);
  };

  const FormValidation = (fieldName, value) => {
    let error = "";

    switch (fieldName) {
      case "firstName":
        if (!value) {
          error = "This must be filled with your first name";
        } else if (/[\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value)) {
          error = "Please Enter Valid First Name";
        }
        break;

      case "lastName":
        if (!value) {
          error = "This must be filled with your last name";
        } else if (/[\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value)) {
          error = "Please Enter Valid Last Name";
        }
        break;

      case "phone":
        if (!value) {
          error = "Please Enter Phone Number";
        } 
        break;

      case "email":
        if (value?.length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Please Enter Valid Email";
        }
        break;

      case "country":
        if (!value) {
          error = "This must be filled with your Company Headquarters";
        }
        break;

      case "state":
        if (!value) {
          error = "This must be filled with your state";
        }
        break;

      case "details":
        if (!value) {
          error = "This must be filled with what you'd like to discuss";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const HandleChanges = (event) => {
    
    const {
      target: { name, value },
    } = event;

    if (name === "country") {
      getStateByCountry(value);
    }


    // setSupportData((prevData) => ({ ...prevData, [name]: value?.trimLeft() }));

    setSupportData((prevData) => {
      const updatedData = { ...prevData };
      if (value === null || value === "") {
        delete updatedData[name];
      } else {
        updatedData[name] = value?.trimLeft();
      }
      return updatedData;
    });

    const errorMessage = FormValidation(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const onChange = (value, type) => {
    // setSupportData((prevData) => ({ ...prevData, [name]: value?.trimLeft() }));

    setSupportData((prevData) => {
      const updatedData = { ...prevData };
      if (value === null || value === "") {
        delete updatedData[type];
      } else {
        updatedData[type] = value?.trimLeft();
      }
      return updatedData;
    });

    const errorMessage = FormValidation(type, value);
    setErrors((prevErrors) => ({ ...prevErrors, [type]: errorMessage }));
  };

  const FormValidationSubmit = () => {
    let error = false;
    const errorsDetails = {
      firstName: "",
      lastName: "",
      phone: "",
      // email: "", // Add email validation
      country: "",
      state: "",
      details: "",
    };
    if (!supportData?.firstName?.length) {
      error = true;
      errorsDetails.firstName = "This must be filled with your first name";
    }
    if (
      supportData?.firstName?.length &&
      /[\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(supportData?.firstName)
    ) {
      error = true;
      errorsDetails.firstName = "This must be filled with your first name";
    }
    if (!supportData?.lastName?.length) {
      error = true;
      errorsDetails.lastName = "This must be filled with your last name";
    }
    if (
      supportData?.lastName?.length &&
      /[\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(supportData?.lastName)
    ) {
      error = true;
      errorsDetails.lastName = "Please Enter Valid Last Name";
    }
    if (!supportData?.phone?.length) {
      error = true;
      errorsDetails.phone = "Please Enter Phone Number";
    }
    if (supportData?.phone?.length && !/^\d{10}$/.test(supportData?.phone)) {
      error = true;
      errorsDetails.phone = "Please Enter Valid Phone Number";
    }
    if (!supportData?.email?.length) {
      error = true;
      errorsDetails.email = "Please Enter Email";
    }
    if (
      supportData?.email?.length &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(supportData?.email)
    ) {
      error = true;
      errorsDetails.email = "Please Enter Valid Email";
    }
    if (!supportData?.country) {
      error = true;
      errorsDetails.country =
        "This must be filled with your Company Headquartes";
    }
    if (!supportData?.state) {
      error = true;
      errorsDetails.state = "This must be filled with your state";
    }
    if (!supportData?.details) {
      error = true;
      errorsDetails.details = "This must be filled with you like to discuss";
    }
    setErrors({ ...errorsDetails });
    return error;
  };

  const HandleSubmit = () => {
    setLoading(true)
    const FormData = {
      ...supportData,
    };
    
    // const flag = FormValidationSubmit(FormData);
    // if (flag) {
    // } else {
    dispatch(
      setContactAsync({ ...supportData }, (res) => {
        console.log(res);
        if (res?.data?.data && Object.keys(res?.data?.data)?.length > 1) {
          dispatch(resetAsync({}));
          toast.success(res?.data?.message);
          navigate("/thankyou");
          setLoading(false)
        } else {
          toast.error(res?.data?.message);
          setLoading(false)
        }
      })
    );
    // }
  };

  return (
    <section className="contact_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 offset-lg-2">
            <h2 className="heading wow fadeInUp" data-wow-duration=".60s">
              Talk with our <span>Support</span> team
            </h2>
            <p className="head_para wow fadeInUp" data-wow-duration=".60s">
              Looking for some information or want to try one of our paid
              Clarity Client Plans? Submit your information and a Clarity
              representative will revert you back as soon as possible
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-0 offset-md-1 offset-lg-1">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
                <div className="mini_card wow fadeInUp" data-wow-duration="1s">
                  <img alt="icon" src={address} />
                  <div>
                    <h3>Address</h3>
                    <p>${contacts?.address}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
                <div className="mini_card wow fadeInUp" data-wow-duration="1s">
                  <img alt="icon" src={email} />
                  <div>
                    <h3>Email</h3>

                    <p
                      onClick={() => window.open(`mailto:${contacts?.email}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {contacts?.email ? contacts?.email : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
                <div className="mini_card wow fadeInUp" data-wow-duration="1s">
                  <img alt="icon" src={phone} />
                  <div>
                    <h3>Phone</h3>
                    <p
                      onClick={() => window.open(`tel:${contacts?.phone}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {contacts?.phone ? contacts?.phone : ""}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className="form_wrapper wow fadeIn"
                  data-wow-duration="1.60s"
                >
                  <Form>
                 { !loading ? 
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <FormControl
                          type="text"
                          labels="First name"
                          sublabel=""
                          placeholder="Your first name"
                          name="firstName"
                          id={"firstName"}
                          value={supportData?.firstName}
                          handleChange={HandleChanges}
                        />
                        <p className="required">
                          {errors && errors?.firstName !== ""
                            ? errors.firstName
                            : "This must be filled with your first name"}
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <FormControl
                          type="text"
                          labels="Last name "
                          sublabel=""
                          placeholder="Your last name "
                          name="lastName"
                          id={"lastName"}
                          value={supportData?.lastName}
                          handleChange={HandleChanges}
                        />
                        <p className="required">
                          {errors && errors?.lastName !== ""
                            ? errors.lastName
                            : "This must be filled with your last name"}
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 phone_country">
                        {/* <FormControl
                          type="number"
                          labels="Phone Number"
                          // sublabel="(Optional)"
                          placeholder="Your phone number"
                          name="phone"
                          id={"phone"}
                          min={0}
                          value={supportData?.phone}
                          handleChange={HandleChanges}
                        /> */}
                        <label className="form-label" for="formBasicEmail">
                          Phone Number<span></span>
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={value}
                          onChange={(e) => {
                            onChange(e, "phone");
                          }}
                          className="form-control phone_country"
                          defaultCountry="AU"
                          international = {true}
                        />
                        <p className="required">{errors && errors?.phone}</p>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <FormControl
                          type="email"
                          labels="Company email"
                          // sublabel="(Optional)"
                          placeholder="company@gmail.com"
                          name="email"
                          id={"email"}
                          value={supportData?.email}
                          handleChange={HandleChanges}
                        />
                        <p className="required">{errors && errors?.email}</p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <Form.Group className="" controlId="formBasicEmail">
                          <Form.Label>Company Headquarters</Form.Label>
                          <Form.Select
                            className="form-control"
                            aria-label="Default select example"
                            id={"country"}
                            name="country"
                            onChange={(event) => HandleChanges(event)}
                            value={supportData?.country}
                            required
                          >
                            <option
                              defaultValue={true}
                              value={""}
                              selected
                              disabled
                              hidden
                            >
                              Select Country
                            </option>
                            {countries.length &&
                              countries?.map((country) => {
                                return (
                                  <option value={country?.name}>
                                    {country?.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                        <p className="required">
                          {errors && errors?.country !== ""
                            ? errors.country
                            : "This must be filled with your Company Headquartes"}
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <Form.Group className="" controlId="formBasicEmail">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            className="form-control"
                            aria-label="Default select example"
                            name="state"
                            id={"state"}
                            onChange={(event) => HandleChanges(event)}
                            value={supportData?.state}
                            required
                          >
                            <option
                              defaultValue={true}
                              value={""}
                              selected
                              disabled
                              hidden
                            >
                              Select State
                            </option>
                            {states?.length &&
                              states?.map((state) => {
                                return <option value={state}>{state}</option>;
                              })}
                          </Form.Select>
                        </Form.Group>
                        <p className="required">
                          {errors && errors?.state !== ""
                            ? errors.state
                            : "This must be filled with your state"}
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <Form.Group className="" controlId="formBasicEmail">
                          <Form.Label>
                            What would you like to discuss?
                          </Form.Label>
                          <textarea
                            className="form-control"
                            placeholder="Tell is about your team and provide some details about your organisation"
                            name="details"
                            id="details"
                            onChange={(event) => HandleChanges(event)}
                            value={supportData?.details}
                          />
                        </Form.Group>
                        <p className="required">{errors && errors?.details}</p>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <Button
                          variant=""
                          className="submit_btn"
                          disabled={
                            !Object.values(supportData).some(
                              (value) => value === ""
                            ) && !Object.values(errors).some((error) => !!error)
                              ? false
                              : true
                          }
                          style={{
                            backgroundColor:
                              !Object.values(supportData).some(
                                (value) => value === ""
                              ) &&
                              !Object.values(errors).some((error) => !!error)
                                ? ""
                                : "#677487",
                          }}
                          onClick={() => HandleSubmit()}
                        >
                          Submit
                        </Button>
                      </div>
                    </div> : <div
                    className=""
                    style={{
                      marginTop: "200px",
                      height: "300px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        style={{ height: "30px", width: "40px" }}
                        src={logo}
                        alt="logo"
                      />
                    </div>
                    <div className="three col">
                      <div className="loader" id="loader-4">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row down_sec_contact">
          <div className="col-md-12">
            <h4 className="wow fadeInUp" data-wow-duration="1s">
              wanna seek more <span>help?</span>
            </h4>
          </div>

          <div className="col-md-6 mb-4 mb-md-0">
            <div className="down_mini_sec wow fadeInUp" data-wow-duration="1s">
              <h5>
                Questions, Feedback, or Just Want to Say Hello? Reach Out to Us!
              </h5>
              <button>
                FAQ
                <img alt="icon" src={Arrow_Swirl} />
              </button>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="down_mini_sec two wow fadeInUp"
              data-wow-duration="1s"
            >
              <h5>
                If you're ever unsure, you can watch video tutorials to easily
                understand{" "}
              </h5>
              <Link to={"/tutorials"}>
                <button>
                  Watch Tutorials
                  <img alt="icon" src={Arrow_Swirl} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
